import React from 'react';
import Apollo from '../../../content/assets/logos/apollo.svg';
import Auth0 from '../../../content/assets/logos/auth0.svg';
import Compose from '../../../content/assets/logos/compose.svg';
import Gatsby from '../../../content/assets/logos/gatsby.svg';
import Github from '../../../content/assets/logos/github.svg';
import GraphCms from '../../../content/assets/logos/graphcms.svg';
import Graphql from '../../../content/assets/logos/graphql.svg';
import Heroku from '../../../content/assets/logos/heroku.svg';
import Hubspot from '../../../content/assets/logos/hubspot.svg';
import Javascript from '../../../content/assets/logos/javascript.svg';
import MongoDb from '../../../content/assets/logos/mongodb.svg';
import Nextjs from '../../../content/assets/logos/nextjs.svg';
import Node from '../../../content/assets/logos/nodejs.svg';
import Pepsi from '../../../content/assets/logos/pepsi.svg';
import Postgresql from '../../../content/assets/logos/postgresql.svg';
import Prs from '../../../content/assets/logos/prs.svg';
import Rails from '../../../content/assets/logos/rails.svg';
import ReactJs from '../../../content/assets/logos/reactjs.svg';
import Sass from '../../../content/assets/logos/sass.svg';
import Sequelize from '../../../content/assets/logos/sequelize.svg';
import Shopify from '../../../content/assets/logos/shopify.svg';
import Slack from '../../../content/assets/logos/slack.svg';
import Spotify from '../../../content/assets/logos/spotify.svg';
import Telegram from '../../../content/assets/logos/telegram.svg';
import Trello from '../../../content/assets/logos/trello.svg';
import Vim from '../../../content/assets/logos/vim.svg';
import VSCode from '../../../content/assets/logos/visual-studio-code.svg';

import './styles.css';

const Likes = () => {
  return (
    <section>
      <h4 style={{ textAlign: 'center', margin: '0 0 10px 0' }}>Tech</h4>
      <hr />
      <div className="likes-container">
        <div>
          <Apollo />
        </div>
        <div>
          <Gatsby />
        </div>
        <div>
          <Graphql />
        </div>
        <div>
          <Javascript />
        </div>
        <div>
          <MongoDb />
        </div>
        <div>
          <Nextjs />
        </div>
        <div>
          <Node />
        </div>
        <div>
          <Postgresql />
        </div>
        <div>
          <Rails />
        </div>
        <div>
          <ReactJs />
        </div>
        <div>
          <Sass />
        </div>
        <div>
          <Sequelize />
        </div>
      </div>

      <h4 style={{ textAlign: 'center', margin: '60px 0 10px 0' }}>
        Tools & Services
      </h4>

      <hr />
      <div className="likes-container">
        <div>
          <Auth0 />
        </div>
        <div>
          <Compose />
        </div>
        <div>
          <Github />
        </div>
        <div>
          <GraphCms />
        </div>
        <div>
          <Heroku />
        </div>
        <div>
          <Hubspot />
        </div>
        <div>
          <Shopify />
        </div>
        <div>
          <Slack />
        </div>
        <div>
          <Telegram />
        </div>
        <div>
          <Trello />
        </div>
        <div>
          <Vim />
        </div>
        <div>
          <VSCode />
        </div>
      </div>

      <h4 style={{ textAlign: 'center', margin: '60px 0 10px 0' }}>
        Other things I like
      </h4>
      <hr />
      <div className="likes-container">
        <div>
          <Prs />
        </div>
        <div>
          <Pepsi />
        </div>
        <div>
          <Spotify />
        </div>
      </div>
    </section>
  );
};

export default Likes;
